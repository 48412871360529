import React from 'react';
import { useState } from 'react';
import { Switch, Redirect, Router } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Events from './components/atoms/events';
import HomePage from './components/pages/home';
import NewNav from './components/atoms/Nav/index'
import HomePageNew from './components/pages/newHome/home';
import NewHomePage from './components/pages/homeNew/index';
import TeamPage from './components/pages/team/index';
import ProjectPage from './components/pages/project/index';
import ProjectDetailPage from './components/pages/projectDetails/index';
import AboutPage from './components/pages/about';
import Donate from './components/pages/donate';
import LoginPage from './components/pages/login';
import Donationdash from './components/pages/NGO_donation';
import Myproject from './components/pages/NGO_myproject';
// import Schedule from './components/pages/NGO_schedule';
import Sidebar from './components/Sidebar';
import { Calendar } from '@material-ui/pickers';
import Schedule from './components/pages/NGO_schedule';
import Login from './components/pages/login';
import NotAnNGO from './components/pages/notAnNGO';
import Donor from './components/pages/donor';
import EyecracyCenter from './components/pages/eyecracyCenter';
import { GeneralContext } from "./context/GeneralContextProvider";
import Checkout from "./components/pages/checkout";
import Discover from './components/pages/Discover';
import ShowInterest from "./components/pages/showInterest";
// import ThemeWrapper from './components/templates/themewrapper';
import { baseAddress } from './constants';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import TestMap from './components/pages/TestMap/index'
import TestProject from './components/pages/TestProject/index'
import NGOPage from './components/pages/NGO';
import TestMap1 from './components/pages/testMap1'
import EyeCracyCenter from './components/pages/eyecracyCenter';
import Discoverus from './components/pages/Discover-us';
import Welcome from './components/pages/Welcome'
import LearnMore from './components/pages/Learn_more'

function App({ }) {
  const stripePromise = loadStripe(
    "pk_live_51N7Ic3Ggg9gYWr4n6TL9CXi9wu7JNEBu23bn6D5VECgGMODVrfw2rrhCMSYdr3CaQRdlR15rkoSi61OCcLhqr9by00NpwsVBaO"
  );

  // const { showSideBar, setShowSideBar } = React.useContext(GeneralContext)

  console.log('baseAddress', baseAddress);
  return (
    <>
      {/* <ThemeWrapper> */}

      {/* <Route path="/homepage" >
            <HomePage />
        </Route>  */}
        {/*
      <Sidebar>
        <Switch>
          <Route path={baseAddress + "/Discover"} >
             <Discover /> 
             <Discoverus />
          </Route>
          <Route path={baseAddress + "/donate"}>
            <Elements stripe={stripePromise}>
              <Donate stripe={stripePromise} />
            </Elements>
          </Route>
          <Route path={baseAddress + "/login"} >
            <Login />
          </Route>
          <Route path={baseAddress + "/donor"} >
            <Donor />
          </Route>
          <Route path={baseAddress + "/eyecracyCenter"} >
            <EyeCracyCenter />
          </Route>
          <Route path={baseAddress + "/checkout"} >
            <Checkout />
          </Route>
          <Route path={baseAddress + "/events"} >
            <Schedule />
          </Route>
          <Route path={baseAddress + "/Dashboard"}>
            <Donationdash />
          </Route>
          <Route path={baseAddress + "/myprojects"}>
            <Myproject />
          </Route>
          <Route path={baseAddress + "/testproject/:id"}>
            <TestProject />
          </Route>
          <Route path={baseAddress + "/map"}>
            <TestMap />
          </Route>
          <Route path={baseAddress + "/testmap1"}>
            <TestMap1 />
          </Route>
          <Route path={baseAddress + "/ngopage"}>
            <NGOPage />
          </Route>
          <Route path={baseAddress + "/welcome"} >
            <Welcome />
          </Route>
          <Route path={baseAddress + "/learnmore"} >
            <LearnMore />
          </Route>
          <Route path={baseAddress + "/notanNGO"}>
            <NotAnNGO />
          </Route>
          <Route path={baseAddress + "/homeV2"}>
            <NewHomePage />
          </Route>
          <Route path={baseAddress + "/aboutUs"}>
            <AboutPage />
          </Route>
          <Route path={baseAddress + "/team"}>
            <TeamPage />
          </Route>
          <Route path={baseAddress + "/project"}>
            <ProjectPage />
          </Route>
          <Route path={baseAddress + "/projectDetail"}>
            <ProjectDetailPage />
          </Route>
          <Route path={"/"} >
            <HomePage />
          </Route>
        </Switch>
      </Sidebar>*/}

    <BrowserRouter>
      <NewNav />
      <Switch>
        <Route exact path="/" component={NewHomePage} />
        <Route path="/team" component={TeamPage} />
        <Route path="/aboutUs" component={AboutPage} />
        <Route path="/project" component={ProjectPage} />
        <Route path={"/donate"}>
            <Elements stripe={stripePromise}>
              <Donate stripe={stripePromise} />
            </Elements>
          </Route>
      </Switch>
    </BrowserRouter>

      {/* </ThemeWrapper> */}
    </>
  );
}

export default App;